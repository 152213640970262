import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import spacings from 'theme/spacings';
import { size } from 'theme/utils/functions';

import LiveOdds from 'components/data/LiveOdds';
import Article from 'components/common/Article';
import WidgetShorts from 'components/common/WidgetShorts';
import StaticBanner from 'components/common/StaticBanner';

import 'react-loading-skeleton/dist/skeleton.css';
import HomeArticleHighlightWrapper from './styles';

const HomeArticleHighlight = ({
  isMobile,
  liveEvents,
  highlightNews = [],
  youtubeShorts = [],
}) => {
  const [highlightPrimary, highlightSecondary1, highlightSecondary2] =
    highlightNews;

  const skeletonTemplate = getSize => (
    <div className='skeleton-container'>
      <Skeleton
        height={getSize === 'large' ? '45rem' : '20rem'}
        style={{ marginBottom: spacings.md }}
      />
      <Skeleton
        height={20}
        width='60%'
        style={{ marginBottom: spacings.sm }}
      />
      <Skeleton
        height={20}
        width='80%'
      />
    </div>
  );

  const renderContent = (articleData, getSize, extraProps = {}) =>
    size(highlightNews) > 0 ? (
      <Article
        titleTag='h2'
        thumbnailSize={getSize}
        data={articleData?.noticia}
        {...extraProps}
      />
    ) : (
      skeletonTemplate(getSize)
    );

  return (
    <HomeArticleHighlightWrapper>
      <div className='highlight__primary'>
        {renderContent(highlightPrimary, 'large', {
          titleTypography: 'heading1',
          className: 'highlight__primary',
          priority: true,
        })}
      </div>

      <div className='highlight__secondary'>
        {renderContent(highlightSecondary1, 'small')}
        {renderContent(highlightSecondary2, 'small')}
      </div>

      <div className='highlight__sidebar'>
        <LiveOdds
          data={liveEvents}
          isMobile={isMobile}
          className='highlight__liveOdds'
        />

        <WidgetShorts
          className='highlight__shorts'
          youtubeShorts={youtubeShorts}
        />
      </div>

      <StaticBanner
        className='highlight__ads'
        link='https://betnacional.com/?p=GameArena'
        title='Betnacional - A Bet do Brasil'
        mobileImage='/images/banners/banner1-mobile.jpg'
        desktopImage='/images/banners/banner1-desktop.jpg'
      />
    </HomeArticleHighlightWrapper>
  );
};

HomeArticleHighlight.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  liveEvents: PropTypes.shape({
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  highlightNews: PropTypes.arrayOf(
    PropTypes.shape({
      noticia: PropTypes.shape({}).isRequired,
    })
  ),
};

export default React.memo(HomeArticleHighlight);
